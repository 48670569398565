import React, { FC, useEffect, useState } from "react";
import styled from "styled-components";

interface Props {
  dark?: boolean;
  loaded: boolean;
}

const Container = styled.div<Props>`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 9999999999999999;
  background-color: ${(props) => (props.dark ? "#000" : "#fff")};
  display: flex;
  margin: auto 0;
  justify-content: center;
  align-items: center;

  ${(props) =>
    props.loaded
      ? `
    visibility: hidden;
    -webkit-transform: translateY(-100%);
    /* Chrome, Opera 15+, Safari 3.1+ */
    -ms-transform: translateY(-100%);
    /* IE 9 */
    transform: translateY(-100%);
    /* Firefox 16+, IE 10+, Opera */
    -webkit-transition: all 0.3s 1s ease-out;
    transition: all 0.3s 1s ease-out;
  `
      : ""}
`;

const Loader = styled.div<Props>`
  display: block;
  position: relative;
  width: 200px;
  height: 200px;
  margin: -75px 0 0 -75px;
  border-radius: 50%;
  border: 5px solid transparent;
  border-top-color: ${(props) => (props.dark ? "#CECECB" : "#02255e")};
  -webkit-animation: spin 2s linear infinite;
  /* Chrome, Opera 15+, Safari 5+ */
  animation: spin 2s linear infinite;
  /* Chrome, Firefox 16+, IE 10+, Opera */
  z-index: 1001;
  margin: auto 0;


  ${(props) =>
    props.loaded
      ? `
    opacity: 0;
    -webkit-transition: all 0.3s ease-out;
    transition: all 0.3s ease-out;
  `
      : ""}

  &:before {
    content: "";
    position: absolute;
    top: 5px;
    left: 5px;
    right: 5px;
    bottom: 5px;
    border-radius: 50%;
    border: 5px solid transparent;
    border-top-color: #f0cb65;
    -webkit-animation: spin 3s linear infinite;
    /* Chrome, Opera 15+, Safari 5+ */
    animation: spin 3s linear infinite;
    /* Chrome, Firefox 16+, IE 10+, Opera */
  }

  &:after {
    content: "";
    position: absolute;
    top: 15px;
    left: 15px;
    right: 15px;
    bottom: 15px;
    border-radius: 50%;
    border: 5px solid transparent;
    border-top-color: ${(props) => (props.dark ? "#CECECB" : "#02255e")};
    -webkit-animation: spin 1.5s linear infinite;
    /* Chrome, Opera 15+, Safari 5+ */
    animation: spin 1.5s linear infinite;
    /* Chrome, Firefox 16+, IE 10+, Opera */
  }

  @-webkit-keyframes spin {
    0% {
      -webkit-transform: rotate(0deg);
      /* Chrome, Opera 15+, Safari 3.1+ */
      -ms-transform: rotate(0deg);
      /* IE 9 */
      transform: rotate(0deg);
      /* Firefox 16+, IE 10+, Opera */
    }

    100% {
      -webkit-transform: rotate(360deg);
      /* Chrome, Opera 15+, Safari 3.1+ */
      -ms-transform: rotate(360deg);
      /* IE 9 */
      transform: rotate(360deg);
      /* Firefox 16+, IE 10+, Opera */
    }
  }

  @keyframes spin {
    0% {
      -webkit-transform: rotate(0deg);
      /* Chrome, Opera 15+, Safari 3.1+ */
      -ms-transform: rotate(0deg);
      /* IE 9 */
      transform: rotate(0deg);
      /* Firefox 16+, IE 10+, Opera */
    }

    100% {
      -webkit-transform: rotate(360deg);
      /* Chrome, Opera 15+, Safari 3.1+ */
      -ms-transform: rotate(360deg);
      /* IE 9 */
      transform: rotate(360deg);
      /* Firefox 16+, IE 10+, Opera */
    }
  }
`;

const LoaderSection = styled.div<Props>`
  position: fixed;
  top: 0;
  width: 51%;
  height: 100%;
  z-index: 1000;
  -webkit-transform: translateX(0);
  /* Chrome, Opera 15+, Safari 3.1+ */
  -ms-transform: translateX(0);
  /* IE 9 */
  transform: translateX(0);
  /* Firefox 16+, IE 10+, Opera */
`;

const LoaderSectionLeft = styled(LoaderSection)`
  left: 0;

  ${(props) =>
    props.loaded
      ? `
    -webkit-transform: translateX(-100%);
    /* Chrome, Opera 15+, Safari 3.1+ */
    -ms-transform: translateX(-100%);
    /* IE 9 */
    transform: translateX(-100%);
    /* Firefox 16+, IE 10+, Opera */
    -webkit-transition: all 0.7s 0.3s cubic-bezier(0.645, 0.045, 0.355, 1.000);
    transition: all 0.7s 0.3s cubic-bezier(0.645, 0.045, 0.355, 1.000);
  `
      : ""}
`;

const LoaderSectionRight = styled(LoaderSection)`
  right: 0;

  ${(props) =>
    props.loaded
      ? `
    -webkit-transform: translateX(-100%);
    /* Chrome, Opera 15+, Safari 3.1+ */
    -ms-transform: translateX(-100%);
    /* IE 9 */
    transform: translateX(-100%);
    /* Firefox 16+, IE 10+, Opera */
    -webkit-transition: all 0.7s 0.3s cubic-bezier(0.645, 0.045, 0.355, 1.000);
    transition: all 0.7s 0.3s cubic-bezier(0.645, 0.045, 0.355, 1.000);
  `
      : ""}
`;

const MainLoader: FC<Props> = ({ dark, loaded }) => {
  return (
    <Container dark={dark} loaded={loaded}>
      <Loader dark={dark} loaded={loaded} />
      <LoaderSectionLeft loaded={loaded} />
      <LoaderSectionRight loaded={loaded} />
    </Container>
  );
};

export const MainLoaderWithTimeout: FC<{ delay: number; dark?: boolean }> = ({
  dark,
  delay,
}) => {
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    setTimeout(() => {
      setLoaded(true);
    }, delay);
  }, [delay]);

  return <MainLoader dark={dark} loaded={loaded} />;
};

export default MainLoader;
